// log
import store from "../store";

const fetchDataRequest = () => {
    return {
        type: "CHECK_DATA_REQUEST",
    };
};

const fetchDataSuccess = (payload) => {
    return {
        type: "CHECK_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchDataFailed = (payload) => {
    return {
        type: "CHECK_DATA_FAILED",
        payload: payload,
    };
};

export const fetchData = (account) => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        try {
            let totalSupply = await store
                .getState()
                .blockchain.smartContract.methods.totalSupply()
                .call();
            let currentSupply = await store
                .getState()
                .blockchain.smartContract.methods.currentTicketId()
                .call();
            let paused = await store
                .getState()
                .blockchain.smartContract.methods.paused()
                .call();
            let nftsToClaim = await store
                .getState()
                .blockchain.smartContract.methods.getUserClaimableTicketCount(account)
                .call();
            let userTickets = await store
                .getState()
                .blockchain.smartContract.methods.usersTickets(account)
                .call();
            dispatch(
                fetchDataSuccess({
                    totalSupply,
                    paused,
                    currentSupply,
                    nftsToClaim,
                    userTickets
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchDataFailed("Could not load data from contract."));
        }
    };
};
