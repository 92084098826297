const initialState = {
  loading: false,
  totalSupply: 0,
  currentSupply: 0,
  paused: false,
  nftsToClaim: 0,
  userTickets: 2,
  cost: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        currentSupply: action.payload.currentSupply,
        nftsToClaim: action.payload.nftsToClaim,
        userTickets: action.payload.userTickets,
        paused: action.payload.paused,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
